import { Button, PopOver, Text, Title } from "components/commons";
import styles from "../promo-details.module.scss";
import locale from "localization";
import { useModal, useRouter } from "hooks";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/EditOutlined";
import { SelectStationsModal } from "components/modals";
import { useCallback, useEffect, useMemo, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import useStations from "hooks/useStations";
import { FormMode } from "enums";
import { CircularProgress } from "@mui/material";

const ParticipatingStationsInput = ({ value, onChange, name, hideTitle, isView }) => {
  const { allStations, loading } = useStations();

  const router = useRouter();
  const pageMode = router.query?.mode || FormMode.Add;

  const [selectedStations, setSelectedStations] = useState(value);
  const selectStationsModal = useModal();

  const openSelectionStations = () => {
    selectStationsModal.show({
      title: locale.selectStations,
    });
  };

  const mappedStations = useMemo(() => {
    if (selectedStations?.length === 0 || allStations?.length === 0) return [];
    return selectedStations
      ?.map((stationCode) => allStations?.find((station) => stationCode === station?.stationCode))
      ?.filter((station) => !!station);
  }, [allStations, selectedStations]);

  const buttonState = useMemo(() => {
    if (selectedStations.length > 0)
      return {
        icon: <EditIcon />,
        text: locale.selectStations,
      };

    return {
      icon: <CheckIcon />,
      text: locale.selectStations,
    };
  }, [selectedStations]);

  const displayStations = useMemo(() => {
    return selectedStations?.length === allStations?.length ||
      mappedStations?.length === allStations?.length
      ? locale.all
      : mappedStations?.length +
          " " +
          (mappedStations?.length === 1 ? locale.station : locale.stations);
  }, [allStations?.length, mappedStations?.length, selectedStations?.length]);

  const emitStationCodes = useCallback(
    (stationCodes) => {
      setSelectedStations(stationCodes);
      onChange?.(name, { value: stationCodes });
    },
    [name, onChange]
  );

  const handleOnChange = useCallback(
    (stationCodes) => {
      selectStationsModal.close();
      emitStationCodes(stationCodes);
    },
    [emitStationCodes, selectStationsModal]
  );

  useEffect(() => {
    if (value.length > 0 && pageMode === FormMode.Edit && selectedStations.length === 0) {
      emitStationCodes(value);
    }
  }, [emitStationCodes, value, pageMode, selectedStations]);

  if (loading) return <CircularProgress size={20} color="primary" />;

  return (
    <div>
      <SelectStationsModal
        {...selectStationsModal}
        value={selectedStations}
        onChange={handleOnChange}
        allStations={allStations}
      />
      {!hideTitle && <Title className={styles.subtitle}>{locale.participatingStations}</Title>}
      <div className={styles.selectStationsActions}>
        {!isView && (
          <Button
            onClick={openSelectionStations}
            startIcon={buttonState.icon}
            variant="outlined"
            className={styles.button}
            loading={allStations.length === 0}
            disabled={allStations.length === 0}
          >
            {buttonState.text}
          </Button>
        )}
        {isView && loading && <CircularProgress size={20} />}
        {isView && !loading && mappedStations?.length === 0 && (
          <Text color={"slateblue"} strong>
            0 {locale.stations}
          </Text>
        )}
        {mappedStations?.length > 0 && (
          <>
            <PopOver
              style={{ marginLeft: "4em" }}
              contentStyle={{
                width: "400px",
              }}
              position="right"
              content={
                <div>
                  <Text color={"slateblue"} strong>
                    {locale.participating}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      marginTop: "10px",
                    }}
                  >
                    {mappedStations?.map(({ name }, i) => {
                      return <span key={name + "-" + i}>{name}</span>;
                    })}
                  </div>
                </div>
              }
            >
              <div
                className="link"
                style={{
                  color: "slateblue",
                  marginLeft: !isView && "2.5em",
                }}
              >
                <b>{displayStations}</b>
              </div>
            </PopOver>
            {!isView && <CloseIcon className={styles.close} onClick={() => handleOnChange([])} />}
          </>
        )}
      </div>
    </div>
  );
};

export default ParticipatingStationsInput;
