import { BrowserRouter as Router, Switch } from "react-router-dom";
import Path from "./enums/path";
import AuthPage from "./pages/auth.page";
import AuthorizedPage from "./pages/authorized.page";
import AuthRoute from "./routes/auth.route";
import AuthorizedRoute from "./routes/authorized.route";
import { ErrorModal } from "components/modals";
import React, { useState } from "react";
import { useModal } from "hooks";
import { Image } from "components/commons";
import { CautionSign } from "images";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import ErrorPage from "pages/error.page";
import { CarCrash } from "images";
import UnauthorizedPage from "pages/unauthorized.page";
import firebase from "firebase/compat/app";
import env from "environments/env";
import useBeforeMount from "hooks/useBeforeMount";
import FeatureFlagPage from "pages/featureflag.page";
import AppLoader from "components/commons/app-loader/app-loader";
import { StationProvider } from "contexts/station.context";
import { ProductProvider } from "contexts/product.context";
import { AppProvider, useAppContext } from "contexts/app.context";
import { PromoProvider } from "contexts/promo.context";

function App() {
  const [initializing, setInitializing] = useState(true);
  const errorModal = useModal();

  useBeforeMount(async () => {
    const initializeFirebaseApp = async (config) => {
      return new Promise((resolve, reject) => {
        try {
          firebase.initializeApp(config);
          setTimeout(() => resolve(), 1000);
        } catch (err) {
          reject(err);
        }
      });
    };

    const initFirebase = async () => {
      try {
        await initializeFirebaseApp(env.FIREBASE_CONFIG);
      } catch (err) {
      } finally {
        setInitializing(false);
      }
    };

    initFirebase();
  });

  return (
    <FeatureFlagPage>
      {initializing ? (
        <AppLoader />
      ) : (
        <AppProvider
          value={{
            errorModal,
            initializing,
            setInitializing,
          }}
        >
          <StationProvider>
            <ProductProvider>
              <PromoProvider>
                <StylesProvider injectFirst>
                  <AppContent />
                </StylesProvider>
                <div className="render-offline-images">
                  <Image src={CautionSign} />
                  <Image src={CarCrash} />
                </div>
              </PromoProvider>
            </ProductProvider>
          </StationProvider>
        </AppProvider>
      )}
    </FeatureFlagPage>
  );
}

function AppContent() {
  const { pageError, errorStatus, errorModal } = useAppContext();

  return (
    <>
      <ErrorModal {...errorModal} />
      <CssBaseline />
      <div className="App">
        {pageError ? (
          errorStatus === 403 || errorStatus === 401 ? (
            <UnauthorizedPage />
          ) : (
            <ErrorPage />
          )
        ) : (
          <Router>
            <Switch>
              <AuthRoute path={Path.Auth} component={AuthPage} />
              <AuthorizedRoute path={Path.Slash} component={AuthorizedPage} />
            </Switch>
          </Router>
        )}
      </div>
    </>
  );
}

export default App;
