import { Path } from "enums";
import ProductDetailsModule from "modules/products/product-details/product-details.module";
import ProductsListModule from "modules/products/products-list.module";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const ProductsPage = () => {
  return (
    <>
      <Switch>
        <Route exact path={Path.Products} component={ProductsListModule} />
        <Route path={Path.AddProduct} component={ProductDetailsModule} />
        <Route path={Path.ProductDetails} component={ProductDetailsModule} />
        <Redirect to={Path.Products} />
      </Switch>
    </>
  );
};

export default ProductsPage;
