import { createContext, useContext, useState } from "react";

const StationContext = createContext(null);

export const StationProvider = ({ children }) => {
  const [allStations, setAllStations] = useState([]);
  return (
    <StationContext.Provider
      value={{
        allStations,
        setAllStations,
      }}
    >
      {children}
    </StationContext.Provider>
  );
};

export const useStationContext = () => {
  const context = useContext(StationContext);
  if (!context) {
    throw new Error("useStationContext must be used within a StationProvider");
  }
  return context;
};

export default StationContext;
