import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext, StationContext } from "contexts";
import { getStations } from "apis";
import useAuth from "./useAuth";
import { PagesKeys } from "enums";
import useMount from "./useMount";

const useStations = (props) => {
  const { apisState } = useContext(AppContext);
  const { allStations, setAllStations } = useContext(StationContext);
  const { stationCodes } = useAuth({ pageKey: PagesKeys.STATIONS });

  const key = "all-stations";
  const getAllStations = useApi({
    api: getStations,
    key: key,
    params: {
      stationCodes,
    },
  });

  const fetch = useCallback(
    async () => {
      const res = await getAllStations?.request(
        {
          page: 1,
          perPage: 1000,
        },
        () => {},
        { useLoader: true }
      );

      if (allStations?.length > 0) {
        return allStations;
      }

      // remove duplicate station code
      const uniqueMap = new Map();
      const stations = res.stations.filter(
        (station) => !uniqueMap.has(station.stationCode) && uniqueMap.set(station.stationCode, true)
      );
      setAllStations(stations);
      props?.onSuccess?.(stations);

      return stations;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useMount(async () => {
    if (props?.initialRun) {
      await fetch();
    }
  });

  return {
    fetch,
    allStations,
    loading: !!apisState?.[key]?.loading,
  };
};

export default useStations;
