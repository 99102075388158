import { ConfirmModal } from "components/modals";
import styles from "../promo-details.module.scss";
import { Text } from "components/commons";
import ImportFile from "components/commons/import-file/import-file";
import { useAlert } from "hooks";
import locale from "localization";
import { useState } from "react";
import { downloadCSV } from "utils";
import useProducts from "hooks/useProducts";
import { isAmountValidV2 } from "utils/number.utils";

const ImportProductsCSV = (modal) => {
  const { addAlert } = useAlert();
  const { allProducts } = useProducts();
  const [csvProducts, setCsvProducts] = useState([]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file || !file.name.endsWith(".csv") || file.type !== "text/csv") {
      addAlert({
        type: "error",
        title: locale.invalidImport,
        subtitle: locale.pleaseUploadValidCSVFile,
        interval: 10000,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      const text = target.result;
      const lines = text.split("\n").filter((line) => line);

      if (lines.length < 2) {
        addAlert({
          type: "error",
          title: locale.invalidImport,
          subtitle: locale.emptyItemsCSV,
          interval: 10000,
        });
        return;
      }

      const headers = lines[0].split(",").map((header) => header.replace(/\r/g, ""));
      const skuIndex = headers.indexOf("SKU");
      const promoIndex = headers.indexOf("Promo Amount");

      if (skuIndex === -1 || promoIndex === -1) {
        return modal?.showInvalidImportModal?.();
      }

      const data = lines.slice(1).map((line) => {
        const values = line.split(",");
        return {
          sku: values[skuIndex].replace(/\r/g, ""),
          amount: values[promoIndex].replace(/\r/g, ""),
        };
      });

      // Validate entries
      const csvSkus = data?.map(({ sku }) => sku);
      const csvAmounts = data?.map(({ amount }) => amount);
      const skus = allProducts.map(({ sku }) => sku);

      const MAX = 999999.99;
      const MIN = 0.01;
      const validSkus = csvSkus?.every((sku) => skus.includes(sku));
      const isAmountValid = csvAmounts.every(
        (amount) => !isNaN(amount) && isAmountValidV2(amount) && amount >= MIN && amount <= MAX
      );
      const duplicateEntries = new Set(csvSkus).size !== csvSkus.length;

      if (!validSkus || !isAmountValid || duplicateEntries) {
        return modal?.showInvalidImportModal?.();
      }

      modal?.setState?.({
        ...modal,
        file: acceptedFiles,
      });
      setCsvProducts(data);
    };
    reader.readAsText(file);
  };

  const handleContinue = () => {
    modal?.onSubmit?.(csvProducts);
    modal?.close?.();
  };

  return (
    <ConfirmModal
      className={styles.importCsvModal}
      {...modal}
      primary={{
        text: locale.continue,
        onClick: handleContinue,
        disabled: !modal?.file,
      }}
      secondary={{
        text: locale.cancel,
        onClick: () => modal?.setState?.({}),
      }}
    >
      <div>
        <Text subtitle>
          Follow the following column order and leave row 1 as column headers to allow system to
          properly read your file:
          <br />
          <br />
          Column 1: Product Name
          <br />
          Column 2: Product Amount
          <br />
          <br />
          Download a{" "}
          <span
            onClick={() =>
              downloadCSV([
                {
                  SKU: "diesel",
                  "Promo Amount": 50.0,
                },
                {
                  SKU: "gas91",
                  "Promo Amount": 50.0,
                },
                {
                  SKU: "gas95",
                  "Promo Amount": 50.0,
                },
                {
                  SKU: "gas97",
                  "Promo Amount": 50.0,
                },
              ])
            }
            className={styles.sampleTemplateText}
          >
            sample-template
          </span>{" "}
          to see an example of the format required.
        </Text>
        <br />
        <ImportFile value={modal?.file} onChange={onDrop} />
      </div>
    </ConfirmModal>
  );
};

export default ImportProductsCSV;
