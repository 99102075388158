import React from "react";

import styles from "./product-list-filter.module.scss";

import {
  Field,
  Filter,
  SearchField,
  Autocomplete,
  Button,
  SelectStation,
} from "components/commons";
import { prettifyProductCategory, prettifyProductType } from "utils/pretty.utils";
import locale from "localization";
import { PagesKeys, ProductCategory } from "enums";
import ProductType from "enums/product-type";
import useAuth from "hooks/useAuth";

const ProductListFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  exporting,
  exportReport,
}) => {
  const { stationCodes: accessStationCodes } = useAuth({ pageKey: PagesKeys.PRODUCTS });
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          <Field
            label={locale.productType}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"type"}
              hasAll
              multiple
              options={[
                {
                  label: prettifyProductType(ProductType.Fuel),
                  value: ProductType.Fuel,
                },
                {
                  label: prettifyProductType(ProductType.Lubes),
                  value: ProductType.Lubes,
                },
                {
                  label: prettifyProductType(ProductType.LPG),
                  value: ProductType.LPG,
                },
              ]}
              {...filterState.type}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
          <Field
            label={locale.productCategory}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"category"}
              hasAll
              multiple
              options={[
                {
                  label: prettifyProductCategory(ProductCategory.WETSTOCK),
                  value: ProductCategory.WETSTOCK,
                },
                {
                  label: prettifyProductCategory(ProductCategory.DRYSTOCK),
                  value: ProductCategory.DRYSTOCK,
                },
              ]}
              {...filterState.category}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
          <Field
            label={locale.station}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <SelectStation
              {...filterState.stationCodes}
              params={{
                stationCodes: accessStationCodes,
              }}
              isAll={filterState.stationCodes.isSelectedAll}
              onChange={(_, { value, label, isAll }) => {
                modifyFilter({
                  stationCodes: {
                    value: value,
                    label,
                    isSelectedAll: isAll,
                  },
                });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={`${locale.productCode}, ${locale.productName}`}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>

        {exportReport && exporting && (
          <>
            <Field className={styles.downloadButton}>
              <Button
                startIcon={<span className="icon-download" />}
                onClick={exportReport}
                exportButton
                loading={exporting}
              >
                {locale.export}
              </Button>
            </Field>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductListFilter;
