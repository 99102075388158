import React from "react";
import Dropzone from "react-dropzone";
import { Image } from "components/commons";
import { ImportIcon } from "images";
import FileIcon from "@material-ui/icons/AttachFileOutlined";
import locale from "localization";
import styles from "./import-file.module.scss";
import { useCallback } from "react";
import { Text } from "..";

const ImportFile = ({ onChange, value }) => {
  const onSelectFile = useCallback(
    (files) => {
      if (files.length > 0) {
        onChange(files);
      }
    },
    [onChange]
  );

  return (
    <div className={styles.content}>
      <Dropzone onDrop={onSelectFile} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            <Image src={ImportIcon} />
            <div>{locale.dragAndDropFilesToUploadOr}</div>
            {value && value[0]?.name && (
              <div>
                {value && (
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FileIcon fontSize="small" />
                    {value[0].name}
                  </div>
                )}
              </div>
            )}
            <div>
              <Text
                strong
                color={"#5349b7"}
                style={{
                  textDecoration: "underline",
                }}
              >
                {value ? locale.replaceFile : locale.addFile}
              </Text>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default ImportFile;
