import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { PagesKeys, Path } from "enums";
import { useRouter } from "hooks";
import locale from "localization";
import ProductListFilter from "./product-list-filter";
import useDataTable from "hooks/useDataTable";
import { getProductsApi } from "apis";
import { initialFilterState } from "./product-filter.state";
import { mapDataToList, mapFilterToRequest } from "./product-list.mapper";
import { productColumn } from "./product-list-column";
import useAuth from "hooks/useAuth";
import useStations from "hooks/useStations";

const ProductsListModule = () => {
  const router = useRouter();
  const { allStations } = useStations({
    initialRun: true,
  });

  const { hasPermission, stationCodes } = useAuth({ pageKey: PagesKeys.PRODUCTS });

  const { filter, search, table } = useDataTable({
    api: {
      api: getProductsApi,
      params: {
        page: 1,
        perPage: 10,
        orderBy: "createdAt",
        stationCodes,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: {
      initialState: initialFilterState(),
      mapper: (fs) => {
        const mappedStationCodes = mapFilterToRequest(fs).stationCodes;
        return {
          ...mapFilterToRequest(fs),
          stationCodes: mappedStationCodes || stationCodes,
        };
      },
    },
    table: {
      key: "products",
      mapper: (products) => mapDataToList({ products, router, allStations }),
      columns: productColumn,
    },
  });

  return (
    <>
      <Intro
        title={locale.products}
        actionText={hasPermission(["addAccess"]) && locale.addProduct}
        actionOnClick={() => router.push(Path.AddProduct)}
      />
      <ProductListFilter {...filter} {...search} />
      <DataTableV2 {...table} paginationV2 />
    </>
  );
};

export default ProductsListModule;
