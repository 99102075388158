import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext, PromoContext } from "contexts";
import { getPromoListApi } from "apis";
import PromoTypeEnum, { PromoTypeToApiEnum } from "enums/promo-type.enum";
import useAuth from "./useAuth";
import { PagesKeys, Role } from "enums";

const usePromos = (props) => {
  const { apisState } = useContext(AppContext);
  const { allPromos, setAllPromos } = useContext(PromoContext);
  const { stationCodes } = useAuth({ pageKey: PagesKeys.PROMO });

  const getAllPromos = useApi({
    api: getPromoListApi,
    key: props?.key,
    params: {
      stationCodes,
    },
  });

  const fetch = useCallback(
    async (props) => {
      const res = await getAllPromos?.request(
        {
          page: 1,
          perPage: 1000,
          discountType: PromoTypeToApiEnum[PromoTypeEnum.DiscountPerUnit],
          status: props?.status && props?.status?.length > 0 ? props.status : undefined,
        },
        () => 0,
        { useLoader: true }
      );

      const promos = res.promos;
      if (props?.filterResult && typeof props?.filterResult === "function") {
        setAllPromos(props.filterResult(promos));
      } else {
        setAllPromos(promos);
      }

      return promos;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasModifyAccess = ({ creatorType, userRole }) => {
    return (userRole === Role.Dealer) === (creatorType === Role.Dealer);
  };

  return {
    fetch,
    allPromos,
    loading: !!apisState?.[props?.key]?.loading,
    hasModifyAccess,
  };
};

export default usePromos;
