import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

const AppContext = createContext(null);

export const AppProvider = ({ children, value }) => {
  const [appState, setAppState] = useState({ loading: false });
  const [apisState, setApisState] = useState({});
  const [pageError, setPageError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [alerts, setAlerts] = useState([]);

  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const toggleLoading = useCallback((loading) => {
    setAppState((state) => {
      return { ...state, loading };
    });
  }, []);
  return (
    <AppContext.Provider
      value={{
        ...value,
        appState,
        setAppState,
        pageError,
        setPageError,
        errorStatus,
        setErrorStatus,
        toggleLoading,
        apisState,
        setApisState,
        alerts,
        setAlerts,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};

export default AppContext;
