import { createContext, useContext, useState } from "react";

const PromoContext = createContext(null);

export const PromoProvider = ({ children }) => {
  const [allPromos, setAllPromos] = useState([]);

  return (
    <PromoContext.Provider
      value={{
        allPromos,
        setAllPromos,
      }}
    >
      {children}
    </PromoContext.Provider>
  );
};

export const usePromoContext = () => {
  const context = useContext(PromoContext);
  if (!context) {
    throw new Error("usePromoContext must be used within a PromoProvider");
  }
  return context;
};

export default PromoContext;
