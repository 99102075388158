import { BackLink, Intro } from "components/commons";
import { DateTime, FormMode, PagesKeys, Path, PromoStatusEnum, Role } from "enums";
import { useApi, useForm, useModal, useRouter } from "hooks";
import locale from "localization";
import AddEditPromoForm, { modalStyle } from "./add-edit-promo-form";
import ViewPromo from "./view-promo-form";
import { addPromoApi, editPromoApi, getPromoDetailByIdApi } from "apis";
import { mapApiResponseToDataTable } from "../promos-list.mapper";
import useProducts from "hooks/useProducts";
import useStations from "hooks/useStations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { combineDateAndTimeInput, handleRequest, roundUp } from "utils";
import AppLoader from "components/commons/app-loader/app-loader";
// import { EditOutlined } from "@material-ui/icons";
import initialState from "./add-edit-promo-form-state";
import moment from "moment";
import PromoTypeEnum, { PromoTypeToApiEnum } from "enums/promo-type.enum";
import { isNumber } from "lodash";
import { ConfirmModal } from "components/modals";
import useLeavePageBlocker from "hooks/useLeavePageBlocker";
import { hasChanges } from "utils/object.utils";
import styles from "./promo-details.module.scss";
import { stringToDecimal } from "utils/text.utils";
import { validateField } from "hooks/useForm";
import Validation from "utils/validation.utils";
import { EditOutlined } from "@material-ui/icons";
import useAuth from "hooks/useAuth";
import usePromos from "hooks/usePromos";

const PromoDetailsModule = () => {
  const { fetch: fetchAllProducts, allProducts } = useProducts();
  const { fetch: fetchAllStations, allStations } = useStations();
  const { hasModifyAccess } = usePromos();

  const { accessRole } = useAuth({ pageKey: PagesKeys.PROMO });

  const router = useRouter();

  const pageMode = router.query?.mode || FormMode.Add;
  const promoId = router.query?.id;

  const [isListen, setIsListen] = useState(true);

  const leaveModal = useModal();
  const saveModal = useModal();

  const [isProductsSubmittable, setIsProductsSubmittable] = useState(false);

  const {
    request: getPromoDetailById,
    result,
    loading: isGetting,
  } = useApi({
    api: getPromoDetailByIdApi,
  });

  const { request: savePromo, loading: isSaving } = useApi({
    api: pageMode === FormMode.Add ? addPromoApi : editPromoApi,
  });

  const formState = useMemo(() => {
    return initialState(mapApiResponseToDataTable(result), pageMode === FormMode.Edit);
  }, [result, pageMode]);

  const addEditPromoForm = useForm({
    initialState: formState,
  });

  const promoType = addEditPromoForm.fields.promoType.value;

  const hasFormChanges = useMemo(() => {
    if (pageMode === FormMode.Add) return true;

    if (result && allProducts.length > 0) {
      const mapForm = (fields) => {
        let formValues = {};
        formValues.promoDate = {
          startDate: moment(fields.promoDate.value.startDate).format(DateTime.H),
          endDate: moment(fields.promoDate.value.endDate).format(DateTime.H),
        };
        formValues.startTime = moment(fields.startTime.value).format(DateTime.H);
        formValues.endTime = moment(fields.endTime.value).format(DateTime.H);
        formValues.participatingStations = fields.participatingStations.value.sort();
        formValues.promoName = fields.promoName.value;

        if (!fields.transactionCap?.hidden) {
          formValues.transactionCap = roundUp(fields.transactionCap.value, "0.00");
        }

        if (!fields.minPurchase?.hidden) {
          formValues.minPurchase = roundUp(fields.minPurchase.value, "0.00");
        }

        if (!fields.transactionDiscount?.hidden) {
          formValues.transactionDiscount = roundUp(fields.transactionDiscount.value, "0.00");
        }

        if (!fields.product?.hidden) {
          formValues.product = fields.product.value.map(({ sku, value }) => {
            return {
              sku,
              value: roundUp(value),
            };
          });
        }

        return formValues;
      };

      const initialData = mapForm(formState);
      const fieldValues = mapForm(addEditPromoForm.fields);

      return hasChanges(initialData, fieldValues);
    }
    return false;
  }, [result, allProducts, formState, addEditPromoForm, pageMode]);

  const isFormSubmittable = useMemo(() => {
    const { isFormSubmittable } = addEditPromoForm;

    if (promoType === PromoTypeEnum.DiscountPerUnit) {
      return isFormSubmittable && isProductsSubmittable && hasFormChanges;
    }

    return isFormSubmittable && hasFormChanges;
  }, [addEditPromoForm, promoType, hasFormChanges, isProductsSubmittable]);

  const renderPromoDetailsByMode = () => {
    if (isGetting || allProducts.length === 0 || allStations.length === 0) return <AppLoader />;

    if (!isGetting && allProducts.length > 0 && allStations.length > 0) {
      if (pageMode !== FormMode.View) {
        return (
          <AddEditPromoForm
            isFormSubmittable={isFormSubmittable}
            promoDetails={mapApiResponseToDataTable(result)}
            addEditPromoForm={addEditPromoForm}
            formState={formState}
            handleSave={handleSave}
            onChangeIsFormSubmittable={(isProductsSubmittable) =>
              setIsProductsSubmittable(isProductsSubmittable)
            }
          />
        );
      }

      return <ViewPromo {...mapApiResponseToDataTable(result)} />;
    }
  };

  const handleSave = useCallback(async () => {
    const formValues = addEditPromoForm.getFormValues();
    const payload = {
      name: formValues.promoName,
      startDate: moment(
        combineDateAndTimeInput(formValues.promoDate.startDate, formValues.startTime)
      ).toISOString(),
      endDate: moment(
        combineDateAndTimeInput(formValues.promoDate.endDate, formValues.endTime)
      ).toISOString(),
      discountType: PromoTypeToApiEnum[formValues?.promoType],
      promoStations:
        accessRole?.role === Role.Dealer
          ? formValues.participatingStations
          : formValues.participatingStations?.length === allStations?.length
          ? [] // empty = all stations
          : formValues.participatingStations,
      promoDetails: !addEditPromoForm.fields?.product?.hidden
        ? formValues.product.map(({ amount, product: sku, name, variant }) => {
            // const product = allProducts.find((product) => product.sku === sku);
            return {
              sku: sku,
              value: roundUp(
                isNumber(amount) ? amount : parseFloat(amount?.replace(/,/g, "")),
                "0.00"
              ),
              name: name,
              variant: variant,
            };
          })
        : [],
      minPurchase:
        !addEditPromoForm.fields?.minPurchase?.hidden && formValues.minPurchase
          ? stringToDecimal(String(formValues.minPurchase))
          : null,
      transactionDiscount:
        !addEditPromoForm.fields?.transactionDiscount?.hidden && formValues.transactionDiscount
          ? stringToDecimal(String(formValues.transactionDiscount))
          : null,
      transactionCap:
        !addEditPromoForm.fields?.transactionCap?.hidden && formValues?.transactionCap
          ? stringToDecimal(String(formValues?.transactionCap))
          : null,
      creatorType: accessRole?.role === Role.Dealer ? Role.Dealer : "default",
    };

    const handleSaving = async () => {
      await handleRequest(async () => {
        await savePromo({ promoId, body: payload });
        setIsListen(false);
        saveModal.close();
        saveModal.show({
          title: locale.success,
          content: pageMode === FormMode.Add ? locale.successPromoCreated : locale.changesSuccess,
          primary: {
            onClick: () => {
              saveModal.close();
              router.push(Path.PromosList);
            },
          },
          close: () => {
            saveModal.close();
            router.push(Path.PromosList);
          },
          ...modalStyle,
        });
      });
    };

    saveModal.show({
      title: pageMode === FormMode.Add ? `${locale.createPromo}?` : `${locale.saveChanges}?`,
      content: pageMode === FormMode.Add ? locale.confirmSaving : locale.confirmChanges,
      primary: {
        text: pageMode === FormMode.Add ? locale.createPromo : locale.saveChanges,
        onClick: handleSaving,
      },
      secondary: { text: locale.continueEditing },
      ...modalStyle,
      contentStyle:
        pageMode === FormMode.Add ? styles.contentAddPromoModal : modalStyle.contentStyle,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEditPromoForm, pageMode, allProducts]);

  const actionText = useMemo(() => {
    if (
      hasModifyAccess({ creatorType: result?.creatorType, userRole: accessRole?.role }) &&
      pageMode === FormMode.View &&
      (result?.status === PromoStatusEnum.Upcoming || result?.status === PromoStatusEnum.Ongoing)
    ) {
      return {
        actionText: (
          <>
            <EditOutlined fontSize="inherit" style={{ marginRight: "10px" }} /> {locale.edit}
          </>
        ),
        actionOnClick: () => router.push(`${Path.PromosList}/details/${promoId}/edit`),
      };
    }

    if (pageMode === FormMode.Edit) {
      return {
        actionDisabled: !isFormSubmittable,
        actionText: locale.saveChanges,
        actionOnClick: handleSave,
      };
    }
  }, [
    hasModifyAccess,
    result?.creatorType,
    result?.status,
    accessRole?.role,
    pageMode,
    router,
    promoId,
    isFormSubmittable,
    handleSave,
  ]);

  useEffect(() => {
    if (!promoType || isGetting) return;

    addEditPromoForm.modifyForm((fields) => {
      let transactionDiscount = {
        ...initialState().transactionDiscount,
        value: fields.transactionDiscount.value,
        hidden: false,
      };
      let product = {
        ...initialState().product,
        value: fields.product.value,
        hidden: false,
        validations: [Validation.requiredArray({ notEmpty: true })],
      };
      let transactionCap = {
        ...initialState().transactionCap,
        hidden: false,
        value: fields.transactionCap.value,
      };

      let minPurchase = {
        ...initialState().minPurchase,
        hidden: false,
        value: fields.minPurchase.value,
      };

      if (promoType === PromoTypeEnum.PercentDiscount) {
        if (transactionDiscount.value) {
          let transactionDiscountValue = stringToDecimal(String(transactionDiscount.value));
          transactionDiscount = {
            ...transactionDiscount,
            value: roundUp(transactionDiscountValue > 100 ? 100 : transactionDiscountValue),
          };
        }

        minPurchase = {
          ...minPurchase,
          required: false,
          value: "",
          validations: [],
          hidden: true,
        };
      }

      if (promoType === PromoTypeEnum.DiscountPerTransaction) {
        transactionCap = {
          ...transactionCap,
          value: "",
          required: false,
          error: false,
          validations: [],
          hidden: true,
        };
      }

      if (promoType === PromoTypeEnum.DiscountPerUnit) {
        transactionDiscount = {
          ...transactionDiscount,
          value: "",
          required: false,
          validations: [],
          hidden: true,
        };
        minPurchase = {
          ...minPurchase,
          required: false,
          value: "",
          validations: [],
          hidden: true,
        };
      } else {
        product = {
          ...product,
          validations: [],
          required: false,
          value: [],
          hidden: true,
        };
      }

      return {
        product: validateField(product),
        transactionDiscount: transactionDiscount,
        transactionCap,
        minPurchase,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoType, isGetting]);

  useEffect(() => {
    handleRequest(async () => {
      if (pageMode !== FormMode.Add) {
        await getPromoDetailById({ promoId });
      }

      await Promise.all([fetchAllProducts(), fetchAllStations()]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageMode, promoId]);

  useLeavePageBlocker(
    (nextPathname) => {
      if (pageMode === FormMode.Edit) {
        leaveModal.show({
          title: locale.leavePageQuestion,
          content: locale.leavePageQuestionContent,
          secondary: {
            text: locale.continueEditing,
          },
          primary: {
            text: locale.leavePage,
            onClick: () => {
              window.location.href = nextPathname;
            },
          },
          ...modalStyle,
          contentStyle: styles.contentLeavePageModal,
        });
      }
    },
    [],
    { isListen: isListen && pageMode === FormMode.Edit && hasFormChanges }
  );

  return (
    <>
      <ConfirmModal
        {...saveModal}
        primary={{ ...saveModal.primary, disabled: isSaving }}
        loading={isSaving}
        disabled={isSaving}
        closable={!isSaving}
      />
      <ConfirmModal {...leaveModal} />

      <BackLink text={locale.promos} path={Path.PromosList} />
      <Intro
        title={`${locale[pageMode] || "Add"} ${
          !pageMode ? `${locale.promo}: ${promoId}` : "Promo"
        }`}
        {...actionText}
      />
      <br />
      {renderPromoDetailsByMode()}
    </>
  );
};

export default PromoDetailsModule;
