import { isNumber } from "lodash";

const { useMemo, useState, useCallback } = require("react");
const { default: useForm, validateField } = require("./useForm");

const useDynamicFieldsForm = ({ rowId }) => {
  const DEFAULT_VALUE = useMemo(() => {
    let d = {
      dummy: {
        name: "dummy",
        value: 0.01,
      },
    };
    return d;
  }, []);

  const [formState, setFormState] = useState(null);
  const { fields, ...restFormProps } = useForm({
    initialState: formState || DEFAULT_VALUE,
  });

  const addField = (newField) => {
    setFormState({
      ...formState,
      [newField.name]: validateField(newField),
    });
  };

  const addRow = (newFields, returnOnly, overrideIndex) => {
    const newIndex = isNumber(overrideIndex) ? overrideIndex : uniqueIndexes().length;
    let asObject = {};
    newFields.forEach((newField) => {
      asObject = {
        ...asObject,
        [`${newField.name}-${newIndex}`]: {
          ...newField,
          name: `${newField.name}-${newIndex}`,
        },
      };
    });

    if (returnOnly) {
      return asObject;
    } else {
      setFormState((prev) => {
        return {
          ...prev,
          ...asObject,
        };
      });
    }
  };

  const renameFieldNamesInOrder = (fields) => {
    const reorderedFields = {};
    const arr = uniqueIndexes(fields);
    arr.forEach((_, newIndex) => {
      const paddedIndex = String(newIndex);

      Object.entries(fields).forEach(([key, value]) => {
        const [fieldPrefix, fieldIndex] = key.split("-");
        if (Number(fieldIndex) === Number(arr[newIndex])) {
          const newName = `${fieldPrefix}-${paddedIndex}`;
          reorderedFields[newName] = { ...value, name: newName };
        }
      });
    });

    return reorderedFields;
  };

  const addRows = (rows = [], existingRows) => {
    existingRows = existingRows ? existingRows : getRowsFields();
    let form = {};
    rows.forEach((row, rowIndex) => {
      const existingRow = existingRows.find((prevRow) => {
        return prevRow.some(
          (f) =>
            f?.name?.includes(rowId) &&
            f?.value === row?.find((newField) => newField.name === rowId)?.value
        );
      });
      row = row.map((field) => {
        let existingField = existingRow?.find((existingField) =>
          existingField?.name?.includes(field.name)
        );
        return {
          ...field,
          value: existingField?.value || field?.value,
        };
      });
      form = { ...form, ...addRow(row, true, rowIndex) };
    });

    setFormState(form);
  };

  const removeRow = (rowIndex) => {
    setFormState((formState) => {
      let result = {};
      const rowNames = Object.values(fieldsAsRows[rowIndex])?.map(({ name }) => name);
      Object.values(formState).forEach((field) => {
        if (!rowNames.includes(field.name)) {
          result = {
            ...result,
            [field.name]: field,
          };
        }
      });

      if (Object.values(result).length === 0) {
        return DEFAULT_VALUE;
      }

      return renameFieldNamesInOrder(result);
    });
  };

  const removeField = (name) => {
    const temp = { ...formState };
    delete temp[name];
    const updatedFields = Object.value(temp).length > 0 ? formState : DEFAULT_VALUE;
    setFormState(updatedFields);
  };

  const validFields = useMemo(() => {
    const temp = { ...fields };
    delete temp["dummy"];
    return temp;
  }, [fields]);

  const uniqueIndexes = useCallback(
    (customFields) => {
      const f = customFields || fields;
      let arr = Object.values(f).filter((field) => field.name !== "dummy");

      const fieldsIndexes = arr.map((field) => {
        const splitted = field.name.split("-");
        return Number(splitted[1]);
      });

      return [...new Set(fieldsIndexes)];
    },
    [fields]
  );

  const fieldsAsRows = useMemo(() => {
    const rows = [];
    let arr = Object.values(fields).filter((field) => field.name !== "dummy");
    uniqueIndexes().forEach((index) => {
      let row = {};
      arr.forEach((field) => {
        const splitted = field.name.split("-");
        const fieldIndex = splitted[1];

        if (Number(fieldIndex) === Number(index)) {
          row = {
            ...row,
            [splitted[0]]: field,
          };
        }
      });

      rows.push(row);
    });
    return rows;
  }, [fields, uniqueIndexes]);

  const modifyField = (name, { value }) => {
    if (!formState?.[name]) return;

    setFormState((formState) => {
      let temp = { ...formState };
      temp[name].value = value;
      temp[name] = validateField(temp[name], formState);

      return temp;
    });
  };

  const modifyRow = (indexRow, data) => {
    let row = getRowsFields()[indexRow];

    // row [field, field, ...]

    let rowForm = {};
    Object.entries(data).forEach(([key, value]) => {
      const field = row.find((field) => field.name.split("-")[0] === key);
      if (field) {
        rowForm = {
          ...rowForm,
          [field.name]: {
            ...field,
            value: data[key] && data[key] !== "NULL" ? data[key] : "",
          },
        };
      }
    });

    setFormState((prev) => ({
      ...prev,
      ...rowForm,
    }));
  };

  const getRowsFields = useCallback(() => {
    const rows = [];

    let arr = Object.values(fields).filter((field) => field.name !== "dummy");
    uniqueIndexes().forEach((index) => {
      let row = [];
      arr.forEach((field) => {
        const splitted = field.name.split("-");
        const fieldIndex = splitted[1];

        if (Number(fieldIndex) === Number(index)) {
          row.push(field);
        }
      });

      rows.push(row);
    });
    return rows;
  }, [fields, uniqueIndexes]);

  const getRowsValues = useCallback(() => {
    const rows = [];

    let arr = Object.values(fields).filter((field) => field.name !== "dummy");
    uniqueIndexes().forEach((index) => {
      let row = { id: index };
      arr.forEach((field) => {
        const splitted = field.name.split("-");
        const fieldIndex = splitted[1];

        if (Number(fieldIndex) === Number(index)) {
          row = {
            ...row,
            [splitted[0]]: field.value,
          };
        }
      });

      rows.push(row);
    });
    return rows; // [[field, field...], [], ...]
  }, [fields, uniqueIndexes]);

  const clearRows = () => {
    setFormState(null);
  };

  return {
    ...restFormProps,
    fields: validFields,
    modifyField,
    addField,
    removeField,
    addRow,
    addRows,
    removeRow,
    modifyRow,
    fieldsAsRows,
    getRowsFields,
    getRowsValues,
    clearRows,
  };
};

export default useDynamicFieldsForm;
