import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import styles from "./pop-over.module.scss";
import classNames from "classnames";

const PopOver = ({
  children,
  content,
  simple,
  className,
  onClose,
  style,
  contentStyle,
  position = "left",
  onClick,
  ...restProps
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    onClick?.();
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(undefined);
    if (onClose) {
      onClose();
    }
  };

  const renderPosition = {
    anchorOrigin:
      position === "right"
        ? {
            vertical: "top",
            horizontal: "right",
          }
        : {
            vertical: "top",
            horizontal: "left",
          },
    transformOrigin:
      position === "right"
        ? {
            vertical: "top",
            horizontal: "left",
          }
        : {
            vertical: "top",
            horizontal: "right",
          },
  };

  const arrowClassName = position === "right" ? styles.arrowRight : styles.arrow;

  return (
    <div>
      <div className="pointer" onClick={handleClick}>
        {children}
      </div>
      <Popover
        id={open ? "pop-over-content" : undefined}
        open={open}
        anchorEl={anchorEl}
        className={classNames(classNames(styles.popoverContainer, className))}
        onClose={handleClose}
        style={style}
        {...renderPosition}
        {...restProps}
      >
        {!simple && <div className={arrowClassName}></div>}

        <div style={contentStyle} className={classNames(styles.content, className)}>
          {content}
        </div>
      </Popover>
    </div>
  );
};

export default PopOver;
