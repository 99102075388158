import locale from "localization";
import Path from "./path";
import PagesKeys from "./pages-key";
import Role from "./role";
import { StationsIcon, PromoIcon, ProductsIcon } from "images";
import RolePermissions from "./role-permissions";

let Navigation = [
  {
    label: locale.stations,
    link: Path.Stations,
    icon: StationsIcon,
    exact: true,
    key: PagesKeys.STATIONS,
    permission: [
      {
        role: Role.PortalAdmin,
        fullAccess: true,
      },
      {
        role: Role.Marketing,
        fullAccess: true,
      },
      {
        role: Role.Dealer,
        ...RolePermissions,
        modifyAccess: false,
        addAccess: false,
      },
    ],
  },
  {
    label: locale.promos,
    link: Path.PromosList,
    icon: PromoIcon,
    exact: true,
    key: PagesKeys.PROMO,
    permission: [
      {
        role: Role.PortalAdmin,
        fullAccess: true,
      },
      {
        role: Role.Marketing,
        fullAccess: true,
      },
      {
        role: Role.Dealer,
        fullAccess: true,
      },
    ],
  },
  {
    label: locale.products,
    link: Path.Products,
    icon: ProductsIcon,
    exact: true,
    key: PagesKeys.PRODUCTS,
    permission: [
      {
        role: Role.PortalAdmin,
        fullAccess: true,
      },
      {
        role: Role.Marketing,
        fullAccess: true,
      },
      {
        role: Role.Dealer,
        ...RolePermissions,
        addAccess: false,
        modifyAccess: false,
        voidAccess: false,
      },
    ],
  },
];

export default Navigation;
