import { Text, PopOverMenu, Chip } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { dateTimeFormat } from "utils";
import { locale } from "localization/en";
import styles from "./stations.module.scss";
import StationStatus from "enums/station-status";
import { prettifyStationStatus, prettifyStationSubscription } from "utils/pretty.utils";
import { FormMode, Path } from "enums";
import StationStatusEnum from "enums/station-status";
import { CloudOff, Close, CloudQueueOutlined, CheckOutlined } from "@material-ui/icons";

export const mapDataToList = ({
  stations,
  handleOnClickActionOption,
  router,
  hasModifyAccess = true,
}) => {
  const {
    stationId,
    stationCode,
    name,
    type,
    subscription,
    status,
    stationCheck,
    isConnectedToPts,
    isConnectedToPump,
    updatedAt,
    createdAt,
  } = stations;

  const options = [
    {
      removable: true,
      content: locale.viewDetails,
      onClick: () => {
        router.push(Path.ViewStation(stationId, FormMode.View));
      },
    },
  ];

  const activate = () => {
    return {
      removable: true,
      content: locale.activate,
      onClick: () => {
        handleOnClickActionOption?.(stations, StationStatus.ACTIVE);
      },
    };
  };

  const deactivate = () => {
    return {
      removable: true,
      content: locale.deactivate,
      onClick: () => {
        handleOnClickActionOption?.(stations, StationStatus.DEACTIVATED);
      },
    };
  };

  if (hasModifyAccess) {
    if (status === StationStatus.ACTIVE) {
      options.push(deactivate());
    }

    if (status === StationStatus.DEACTIVATED) {
      options.push(activate());
    }

    if (status === StationStatus.PENDING) {
      options.push(activate());
      options.push(deactivate());
    }

    if (status === StationStatus.MANUAL_MODE) {
      options.push(deactivate());
    }
  }

  const renderStatus = () => {
    const dateTime = {
      [StationStatusEnum.ACTIVE]: updatedAt ? dateTimeFormat(updatedAt) : "",
      [StationStatusEnum.PENDING]: dateTimeFormat(createdAt),
      [StationStatusEnum.DEACTIVATED]: updatedAt ? dateTimeFormat(updatedAt) : "",
      [StationStatusEnum.MANUAL_MODE]: updatedAt ? dateTimeFormat(updatedAt) : "",
    };
    const Icon = isConnectedToPts ? CloudQueueOutlined : CloudOff;

    return (
      <>
        <div style={{ display: "flex", gap: "10px" }}>
          <Chip
            label={prettifyStationStatus(status)}
            status={
              {
                [StationStatusEnum.ACTIVE]: "success",
                [StationStatusEnum.PENDING]: "primary",
                [StationStatusEnum.DEACTIVATED]: "default",
                [StationStatusEnum.MANUAL_MODE]: "warning",
              }[status]
            }
            size="small"
            style={{ padding: "0px 5px" }}
          />
          {status === StationStatusEnum.ACTIVE && (
            <Chip
              icon={<Icon fontSize="small" />}
              status={isConnectedToPts ? "success" : "error"}
              size="small"
              style={{
                backgroundColor: isConnectedToPts && "#8fdb85",
              }}
            />
          )}
        </div>
        <Text className={styles.subtitle}>{dateTime[status]}</Text>
      </>
    );
  };

  const PTSIcon = isConnectedToPts ? CheckOutlined : Close;
  const PumpIcon = isConnectedToPump ? CheckOutlined : Close;

  return {
    code: <Text className={styles.td}>{stationCode ?? ""}</Text>,
    name: <Text className={styles.td}>{name ?? "--"}</Text>,
    type: <Text className={styles.td}>{type ?? "--"}</Text>,
    subscription: (
      <Text className={styles.td}>{prettifyStationSubscription(subscription) ?? "--"}</Text>
    ),
    status: renderStatus(),
    lastChecked: <Text className={styles.td}>{dateTimeFormat(stationCheck?.updatedAt)}</Text>,
    lastTransaction: (
      <Text className={styles.td}>{dateTimeFormat(stationCheck?.lastTransactedAt)}</Text>
    ),
    connectedToPTS: (
      <Chip
        icon={<PTSIcon fontSize="small" htmlColor="white" />}
        status={isConnectedToPts ? "success" : "error"}
        size="small"
        style={{
          backgroundColor: isConnectedToPts && "#8fdb85",
        }}
      />
    ),
    connectedToPump: (
      <Chip
        icon={<PumpIcon fontSize="small" htmlColor="white" />}
        status={isConnectedToPump ? "success" : "error"}
        size="small"
        style={{
          backgroundColor: isConnectedToPump && "#8fdb85",
        }}
      />
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { isConnectedToPump, isConnectedToPts, type, subscription, status, ...fs } = filterState;

  if (!type?.isSelectedAll && type?.value?.length > 0) {
    fs.type = type?.value?.join(",");
  } else {
    fs.type = undefined;
  }

  if (!subscription?.isSelectedAll && subscription?.value?.length > 0) {
    fs.subscription = subscription?.value?.join(",");
  } else {
    fs.subscription = undefined;
  }

  if (!status?.isSelectedAll && status?.value?.length > 0) {
    fs.status = status?.value?.join(",");
  } else {
    fs.status = undefined;
  }

  if (!isConnectedToPump?.isSelectedAll && isConnectedToPump?.value?.length > 0) {
    fs.isConnectedToPump = isConnectedToPump?.value?.[0] === "online";
  } else {
    fs.isConnectedToPump = undefined;
  }

  if (!isConnectedToPts?.isSelectedAll && isConnectedToPts?.value?.length > 0) {
    fs.isConnectedToPts = isConnectedToPts?.value?.[0] === "online";
  } else {
    fs.isConnectedToPts = undefined;
  }

  return {
    ...fs,
  };
};
