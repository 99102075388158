import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { initialFilterState } from "./promos-list-filter.state";
import { mapDataToList, mapFilterToRequest } from "./promos-list.mapper";
import { columns } from "./promos-list-column";
import PromosListFilter from "./promos-list-filter";
import { FormMode, PagesKeys, Path, PromoCancelReasonEnum, PromoStatusEnum } from "enums";
import CancelPromoConfirmModal from "./cancel-promo-modal/cancel-promo-modal";
import { useApi, useForm, useModal, useMount, useRouter } from "hooks";
import Validation from "utils/validation.utils";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import { getPromoListApi, changePromoStatusApi, getPromoDetailByIdApi } from "apis";
import { modalStyle } from "./promo-details/add-edit-promo-form";
import useProducts from "hooks/useProducts";
import useStations from "hooks/useStations";
import useAuth from "hooks/useAuth";
import usePromos from "hooks/usePromos";

const PromosListModule = () => {
  const { fetch: getProducts, allProducts } = useProducts();
  const { allStations } = useStations();
  const { hasModifyAccess } = usePromos();
  const router = useRouter();

  const cancelModal = useModal();
  const successCancelModal = useModal();

  const { stationCodes, accessRole } = useAuth({
    pageKey: PagesKeys.PROMO,
  });

  const initialState = useMemo(() => {
    return {
      reason: {
        name: "reason",
        value: "",
        placeholder: "Select",
        required: true,
        validations: [Validation.required()],
      },
      remarks: {
        name: "remarks",
        value: "",
        placeholder: locale.typeRemarks,
        rowsMax: 7,
        validations: [Validation.maxlength(225)],
      },
    };
  }, []);

  const cancelPromoForm = useForm({
    initialState,
  });

  const [stationPromoId, setStationPromoId] = useState(null);
  const [productsPromoId, setProductsPromoId] = useState(null);

  const handleOnClickActionOption = (promo, actionOption) => {
    if (actionOption !== FormMode.Cancel) {
      return router.push(`${Path.PromosList}/details/${promo?.promoId}/${actionOption}`);
    }

    cancelPromoForm.clearForm();
    cancelModal.show({
      title: `${locale.cancelPromo}?`,
      content: <locale.Populate text={locale.cancelPromoContent} items={[<b>{promo?.name}</b>]} />,
      primary: {
        text: locale.cancelPromo,
      },
      secondary: {
        text: locale.cancel,
      },
      promoId: promo?.promoId,
    });
  };

  const changePromoStatus = useApi({
    api: changePromoStatusApi,
  });

  const { request: getPromoDetailById, result: promoDetails } = useApi({
    api: getPromoDetailByIdApi,
  });

  const onClickViewStations = async (promoId) => {
    setStationPromoId(promoId);
    await getPromoDetailById({
      promoId: promoId,
    });
    setStationPromoId(null);
  };
  const onClickViewProducts = async (promoId) => {
    setProductsPromoId(promoId);
    await getPromoDetailById({
      promoId: promoId,
    });
    setProductsPromoId(null);
  };

  const disableSubmitCancelPromo = useMemo(() => {
    const isOthersReasonEmpty =
      cancelPromoForm.fields?.reason.value === PromoCancelReasonEnum.OTHERS &&
      !cancelPromoForm.fields?.remarks.value;
    return !cancelPromoForm.isFormSubmittable || changePromoStatus?.loading || isOthersReasonEmpty;
  }, [
    cancelPromoForm.fields?.reason.value,
    cancelPromoForm.fields?.remarks.value,
    cancelPromoForm.isFormSubmittable,
    // eslint-disable-next-line no-use-before-define
    changePromoStatus?.loading,
  ]);

  const { filter, search, table } = useDataTable({
    api: {
      api: getPromoListApi,
      mapper: (param) => param,
      params: {
        stationCodes,
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "promos",
      mapper: (promos) =>
        mapDataToList({
          promos,
          products: allProducts || [],
          handleOnClickActionOption,
          allStations,
          onClickViewStations,
          onClickViewProducts,
          loadingStationsId: stationPromoId,
          loadingProductsId: productsPromoId,
          promoDetails,
          hasModifyAccess: hasModifyAccess,
          userRole: accessRole?.role,
        }),
      columns,
    },
  });

  const handleSubmitCancelPromo = useCallback(async () => {
    const { fields } = cancelPromoForm;
    await changePromoStatus.request({
      promoId: cancelModal.promoId,
      body: {
        status: PromoStatusEnum.Cancelled,
        remarks:
          fields?.reason?.value === PromoCancelReasonEnum.OTHERS ? fields?.remarks?.value : "",
        reason: fields?.reason?.value,
      },
    });
  }, [cancelModal, cancelPromoForm, changePromoStatus]);

  useEffect(() => {
    if (changePromoStatus?.called && !changePromoStatus?.error) {
      cancelModal.close();
      table?.refetch();
      successCancelModal?.show({
        title: locale.promoSuccessfullyCancelled,
        content: locale.promoSuccessfullyCancelledContent,
        ...modalStyle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePromoStatus?.error, changePromoStatus?.called]);

  useMount(async () => {
    await getProducts();
  });

  return (
    <>
      <CancelPromoConfirmModal
        {...cancelModal}
        primary={{
          ...cancelModal?.primary,
          disabled: disableSubmitCancelPromo,
          onClick: handleSubmitCancelPromo,
          loading: changePromoStatus?.loading,
        }}
        form={cancelPromoForm}
      />
      <ConfirmModal {...successCancelModal} />

      <Intro
        title={locale.promos}
        actionText={locale.addPromo}
        actionOnClick={() => router.push(Path.AddPromo)}
      />
      <PromosListFilter {...filter} {...search} />
      <DataTableV2 {...table} paginationV2 />
    </>
  );
};

export default PromosListModule;
