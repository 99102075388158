import { UserContext } from "contexts";
import { Portal, UserRoleAccess } from "enums";
import { useCallback, useContext, useMemo } from "react";

const useAuth = ({ pageKey }) => {
  const { user } = useContext(UserContext);
  const accessRole = user?.userAccesses?.find((element) => element.portal === Portal.HOS) || {};
  const { role } = accessRole;

  const stationCodes = useMemo(() => {
    const stationCodes = user?.userAccesses
      ?.filter((roleAccess) => roleAccess.portal === Portal.HOS)
      .map(({ station }) => station?.stationCode);

    if (
      user?.userAccesses &&
      stationCodes?.length > 0 &&
      stationCodes?.every((stationCode) => !isNaN(stationCode))
    ) {
      return stationCodes.join(",");
    }

    return undefined;
  }, [user?.userAccesses]);

  const hasPermission = useCallback(
    ({ permissionsList = [] }) => {
      const checkAccess = (access) => {
        if (access.key !== pageKey) return false;

        if (
          access.permission?.some((permission) => {
            if (permission.role === role && !!permission?.fullAccess) return true;

            return (
              permission.role === role &&
              Object.entries(permission).some(
                ([key, value]) => permissionsList.includes(key) && value === true
              )
            );
          })
        ) {
          return true;
        }

        if (access.items) {
          return access.items.some((item) => checkAccess(item));
        }

        return false;
      };

      return UserRoleAccess.some(checkAccess);
    },
    [pageKey, role]
  );

  const PermissionGuard = useCallback(
    ({ permissionsList = [], children }) => {
      return hasPermission({ permissionsList }) ? children : null;
    },
    [hasPermission]
  );

  return { PermissionGuard, hasPermission, user, accessRole, stationCodes };
};

export default useAuth;
