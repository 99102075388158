import { Highlight } from "components/commons";
import { AppContext } from "contexts";
import { useCallback, useContext } from "react";

const useAlert = () => {
  const { alerts, setAlerts } = useContext(AppContext);

  const addAlert = (alert) => {
    const newAlert = { ...alert, id: Date.now(), isExiting: false };
    setAlerts((prev) => [...prev, newAlert]);

    setTimeout(() => removeAlertWithAnimation(newAlert.id), alert?.interval || 2000);
  };

  const removeAlertWithAnimation = (id) => {
    setAlerts((prev) =>
      prev.map((alert) => (alert.id === id ? { ...alert, isExiting: true } : alert))
    );

    setTimeout(() => {
      setAlerts((prev) => prev.filter((alert) => alert.id !== id));
    }, 500);
  };

  const clearAlerts = () => {
    setAlerts([]);
  };

  const RenderAlerts = useCallback(() => {
    return (
      <div className="alerts">
        {alerts?.map((alert) => (
          <Highlight
            key={alert.id}
            style={alert?.style}
            className={`${alert?.className} ${alert.isExiting ? "exit" : "enter"}`}
            {...{
              [alert?.type || "info"]: true,
              title: alert?.title || "Alert",
              subtitle: alert?.subtitle || "",
            }}
          />
        ))}
      </div>
    );
  }, [alerts]);

  return {
    RenderAlerts,
    addAlert,
    removeAlertWithAnimation,
    clearAlerts,
    alerts,
  };
};

export default useAlert;
