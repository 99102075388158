import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import useStations from "hooks/useStations";

// const SelectStation = ({ placeholder = "", params, ...props }) => {
//   const {
//     request,
//     loading,
//     result = { stations: [], count: 0 },
//   } = useApi({
//     api: getStations,
//     pageError: true,
//     params: params,
//   });

//   const fetch = useCallback(
//     async (params) => {
//       return await request({
//         ...params,
//         perPage: 10000,
//         page: params.page || 1,
//       });
//     },
//     [request]
//   );

//   return (
//     <DropdownPaginated
//       {...props}
//       request={async (params) => {
//         return await fetch(params);
//       }}
//       isMultiple
//       total={result.count}
//       loading={loading}
//       formatRequest={(result) => {
//         const { stations = [] } = result || {};
//         if (stations && stations.length > 0) {
//           const newStations = stations;

//           return newStations.map((d) => {
//             return {
//               label: d.name,
//               value: d.stationCode,
//             };
//           });
//         }
//         return [];
//       }}
//       placeholder={placeholder}
//       selectAllLabel={"All"}
//       selectedAllLabel={"All"}
//       // {...{ allOption: [{ label: "All", value: null }] }}
//     />
//   );
// };
const SelectStation = ({ placeholder = "", params, ...props }) => {
  const { fetch: fetchAllStations, loading, allStations } = useStations();

  const fetch = useCallback(
    async (params) => {
      return {
        stations: await fetchAllStations(),
      };
    },
    [fetchAllStations]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      isMultiple
      total={allStations?.length}
      loading={loading}
      formatRequest={(result) => {
        const { stations = [] } = result || {};
        if (stations && stations.length > 0) {
          const newStations = stations;

          return newStations.map((d) => {
            return {
              label: d.name,
              value: d.stationCode,
            };
          });
        }
        return [];
      }}
      placeholder={placeholder}
      selectAllLabel={"All"}
      selectedAllLabel={"All"}
      selectedLabel={"Station"}
      // {...{ allOption: [{ label: "All", value: null }] }}
    />
  );
};

export default SelectStation;
