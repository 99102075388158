import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext, ProductContext } from "contexts";
import { getProductsApi } from "apis";
import useAuth from "./useAuth";
import { PagesKeys } from "enums";

const useProducts = (props) => {
  const { apisState } = useContext(AppContext);
  const { allProducts, setAllProducts } = useContext(ProductContext);
  const { stationCodes } = useAuth({ pageKey: PagesKeys.PRODUCTS });
  const getAllProducts = useApi({
    api: getProductsApi,
    key: props?.key,
    params: {
      stationCodes,
    },
  });

  const fetch = useCallback(
    async (props) => {
      const res = await getAllProducts?.request(
        {
          page: 1,
          perPage: 1000,
        },
        () => 0,
        { useLoader: true }
      );

      const products = res.products;
      if (props?.filterResult && typeof props?.filterResult === "function") {
        setAllProducts(props.filterResult(products));
      } else {
        setAllProducts(products);
      }

      return products;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetch,
    allProducts,
    loading: !!apisState?.[props?.key]?.loading,
  };
};

export default useProducts;
