import { ProductCategory, ProductType } from "enums";

export const initialFilterState = () => {
  return {
    type: {
      name: "type",
      isSelectedAll: true,
      value: Object.values(ProductType),
    },
    category: {
      name: "category",
      isSelectedAll: true,
      value: Object.values(ProductCategory),
    },
    stationCodes: {
      name: "stationCodes",
      isSelectedAll: true,
    },
    searchKey: "",
    order: "desc",
    orderBy: "createdAt",
    page: 1,
    perPage: 10,
  };
};
