import { Text, PopOverMenu, Image, PopOver } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "./product-list.module.scss";
import locale from "localization";
import { formatAmount } from "utils";
import { prettifyProductCategory, prettifyProductType } from "utils/pretty.utils";
import { FormMode, Path, ProductCategory, ProductType } from "enums";

export const mapDataToList = ({ products, router, allStations }) => {
  const { amount, category, image, name, sku, type, variant, productId, productStations } =
    products;

  const renderParticipatingStations = () => {
    let displayStation = null;
    if (productStations?.length > 0) {
      const station = allStations?.find(
        (station) => station?.stationCode === productStations[0]?.stationCode
      );
      displayStation = (
        <Text key={`${station?.name}`} className={styles.td}>
          {station?.name}
        </Text>
      );
    }

    if (productStations?.length === 1) return displayStation;

    return (
      <>
        {displayStation}
        <PopOver
          style={{
            width: "300px",
          }}
          content={
            <div>
              <Text strong color="#34384b">
                {locale.participating}
              </Text>
              {productStations?.map(({ stationCode }, i) => {
                const station = allStations?.find(
                  (station) => station?.stationCode === stationCode
                );
                return (
                  <Text key={`${station?.name}-${i}`} className={styles.td}>
                    {station?.name}
                  </Text>
                );
              })}
            </div>
          }
        >
          <div className="link" style={{ color: "slateblue" }}>
            <b>{locale.viewAll}</b>
          </div>
        </PopOver>
      </>
    );
  };

  const defaultPrice =
    type === "fuel" || amount === 0 ? "Set via app by cashier" : formatAmount(amount);

  const options = [
    {
      removable: true,
      content: locale.viewDetails,
      onClick: () => {
        router.push(Path.ViewProduct(productId, FormMode.View));
      },
    },
  ];

  const item = (
    <div style={{ display: "flex", gap: "10px" }}>
      <div>
        <Image className={styles.imageContainer} src={image} />
      </div>
      <div>
        <Text className={styles.td}>{name ?? "--"}</Text>
        <Text className={styles.subtitle}>{sku ?? "--"}</Text>
      </div>
    </div>
  );

  return {
    type: (
      <div>
        <Text className={styles.td}>{prettifyProductType(type) ?? "--"}</Text>
        <Text className={styles.subtitle}>
          {type === "fuel" ? "--" : prettifyProductCategory(category?.type) ?? "--"}
        </Text>
      </div>
    ),
    name: item,
    variant: <Text className={styles.td}>{type === "fuel" ? "--" : variant ?? "--"}</Text>,
    amount: <Text className={styles.td}>{defaultPrice}</Text>,
    stations: <Text className={styles.td}>{renderParticipatingStations()}</Text>,
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { type, category, stationCodes, ...fs } = filterState;

  if (!type?.isSelectedAll && type?.value?.length > 0) {
    fs.type = type?.value?.join(",");
  } else {
    fs.type = undefined;
  }

  if (!category?.isSelectedAll && category?.value?.length > 0) {
    var productType = [];

    if (category?.value?.includes(ProductCategory.WETSTOCK)) {
      productType = [ProductType.Fuel];
    }

    if (category?.value?.includes(ProductCategory.DRYSTOCK)) {
      productType = [ProductType.Lubes, ProductType.LPG];
    }

    if (!type?.isSelectedAll) {
      productType = [...productType, ...type?.value];
    }

    var sorted = Array.from(new Set(productType));
    fs.type = sorted.join(",");
  }

  fs.category = undefined;

  if (!stationCodes?.isAll && stationCodes?.value?.length > 0) {
    fs.stationCodes = stationCodes?.value?.map(({ value }) => value).join(",");
  } else {
    fs.stationCodes = undefined;
  }

  return {
    ...fs,
  };
};
