import { Text } from "components/commons";
import locale from "localization/en";

export const columns = [
  {
    key: "promoDate",
    text: <Text color={"#000"}>{locale.promoDateAndTime}</Text>,
    className: "alignToTop",
    style: { textAlign: "top" },
  },
  { key: "promoName", text: locale.promoName, className: "alignToTop" },
  { key: "promoType", text: locale.promoType, className: "alignToTop" },
  {
    key: "product",
    text: (
      <Text color={"#000"}>
        {locale.product} /<br />
        {locale.promoAmount}
      </Text>
    ),
    className: "alignToTop",
  },
  {
    key: "transactionCap",
    text: <Text color={"#000"}>{locale.maxDiscount}</Text>,
    className: "alignToTop",
  },
  {
    key: "participatingStations",
    text: locale.participatingStations,
    className: "alignToTop",
  },
  {
    key: "createdBy",
    text: (
      <>
        <Text color={"#000"}>{locale.createdBy} /</Text>
        <Text color={"#000"}>{locale.createdOn}</Text>
      </>
    ),
    className: "alignToTop",
  },
  {
    key: "status",
    text: (
      <>
        <Text color={"#000"}>{locale.status} / </Text>
        <Text color={"#000"}>{locale.dateOfLastUpdated}</Text>
      </>
    ),
    className: "alignToTop",
  },
  {
    key: "reasonRemarks",
    text: (
      <>
        <Text color={"#000"}>
          {locale.reason} / <br />
          {locale.remarks}
        </Text>
      </>
    ),
    className: "alignToTop",
  },
  { key: "actions", text: locale.actions, className: "alignToTop" },
];
