export const downloadCSV = (data, filename = "sample.csv") => {
  if (!data.length) return;

  const headers = Object.keys(data[0]).join(",") + "\n"; // Get CSV headers
  const rows = data.map((obj) => Object.values(obj).join(",")).join("\n"); // Convert each object to a CSV row
  const csvString = headers + rows;

  // Create a Blob and trigger download
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
